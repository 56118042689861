export const Status = ({ status }) => {

  return status ? (
    <div class="flex items-center space-x-2">
      <span class="flex h-3 w-3">
        <span class="relative inline-flex rounded-full h-3 w-3 bg-green-500"></span>
      </span>
      <span class="text-sm font-medium text-green-500">Active</span>
    </div>
  ) : (
    <div class="flex items-center space-x-2 mt-4">
      <span class="flex h-3 w-3">
        <span class="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
      </span>
      <span class="text-sm font-medium text-red-500">Inactive</span>
    </div>
  );
};
