import React, { useState } from "react";
import { Input } from "../Form/Input";
import { Button } from "../Button";
import Colon from "../Form/Colon";

const EditForm = ({ item, onSave }) => {
  const [formData, setFormData] = useState({ ...item });
  const [msg, setMsg] = useState({ error: false, msg: "" });
  const [isMacValid, setIsMacValid] = useState(true);
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleKeyDown = (e) => {
    if (e.key === "Backspace" && e.target.value.length === 0) {
      const index = macs.indexOf(e.target.name);
      moveFocus(macs[index - 1]);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const arrayOfMac = Object.values(mac);

    arrayOfMac.forEach((m) => {
      if (m.length !== 2) {
        setIsMacValid(false);
        setMsg({ error: true, msg: "Invalid MAC" });
        return
      }
    });

    if (!isMacValid) return;

    const macAddress = arrayOfMac.join(":");
    formData.mac = macAddress;
    if (
      formData.ssid === "" ||
      formData.password === "" ||
      formData.mac === ""
    ) {
      return;
    }

    onSave(formData);
  };

  const arrayOfMacs = formData.mac.split(":")

  const [mac, setMac] = useState({
    mac1: arrayOfMacs[0],
    mac2: arrayOfMacs[1],
    mac3: arrayOfMacs[2],
    mac4: arrayOfMacs[3],
    mac5: arrayOfMacs[4],
    mac6: arrayOfMacs[5],
  });

  const macs = ["mac1", "mac2", "mac3", "mac4", "mac5", "mac6"];

  const handleMac = (e) => {
    const { name, value } = e.target;
    const valueUpper = value.toUpperCase();

    if (/^[0-9A-F]*$/.test(valueUpper) && valueUpper.length < 3) {
      setMac((prev) => ({
        ...prev,
        [name]: valueUpper,
      }));
    } else {
      return;
    }

    if (valueUpper.length === 2) {
      const index = macs.indexOf(name);
      moveFocus(macs[index + 1]);
    } else if (valueUpper.length === 0) {
      const index = macs.indexOf(name);
      moveFocus(macs[index - 1]);
    }
  };

  const moveFocus = (name) => {
    const inputElement = document.querySelector(`input[name="${name}"]`);
    if (inputElement) {
      inputElement.focus();
    }
  };

  return (
    <div className="flex mt-3 mb-3 h-full flex-col justify-center items-center">
      <span className="text-red-500">{msg.msg}</span>
      <form onSubmit={handleSubmit}>
        <div>
          <div className="flex space-x-3">
            <div className="w-14 flex items-center">
              <Input
                type="text"
                name="mac1"
                value={mac.mac1}
                onChange={handleMac}
                onKeyDown={handleKeyDown}
                autoComplete="off"
              />
            </div>
            <Colon />
            <div className="w-14 flex items-center">
              <Input
                type="text"
                name="mac2"
                value={mac.mac2}
                onChange={handleMac}
                onKeyDown={handleKeyDown}
                autoComplete="off"
              />
            </div>
            <Colon />
            <div className="w-14 flex items-center">
              <Input
                type="text"
                name="mac3"
                value={mac.mac3}
                onChange={handleMac}
                onKeyDown={handleKeyDown}
                autoComplete="off"
              />
            </div>
            <Colon />
            <div className="w-14 flex items-center">
              <Input
                type="text"
                name="mac4"
                value={mac.mac4}
                onChange={handleMac}
                onKeyDown={handleKeyDown}
                autoComplete="off"
              />
            </div>
            <Colon />
            <div className="w-14 flex items-center">
              <Input
                type="text"
                name="mac5"
                value={mac.mac5}
                onChange={handleMac}
                onKeyDown={handleKeyDown}
                autoComplete="off"
              />
            </div>
            <Colon />
            <div className="w-14 flex items-center">
              <Input
                type="text"
                name="mac6"
                value={mac.mac6}
                onChange={handleMac}
                onKeyDown={handleKeyDown}
                autoComplete="off"
              />
            </div>
          </div>
        </div>
        <div>
          <Input
            type="text"
            name="ssid"
            label="SSID"
            value={formData.ssid}
            onChange={handleChange}
          />
        </div>
        <div>
          <Input
            type="text"
            label="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
          />
        </div>
        <Button type="submit" name="Save" />
      </form>
    </div>
  );
};

export default EditForm;
