import { guestApi } from "../api/api";

export const logout = async () => {
  await guestApi.post("/auth/logout");
  
  localStorage.removeItem("accessToken");
  delete guestApi.defaults.headers.common["Authorization"];

  window.location.href = '/login'
  
};


export const refreshAccessToken = async () => {
  try {
    const response = await guestApi.post("/auth/get-token");
    
    const token = response.data.token;
     
    return token;
  } catch (error) {
    throw error;
  }
};
