import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
const isProduction = process.env.REACT_APP_ENV !== "development";

const renderApp = isProduction ? (
  <App />
) : (
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

root.render(renderApp);
