import { useEffect, useState } from "react";
import { api } from "../../api/api";
import { Input } from "../Form/Input";
import Colon from "../Form/Colon";
import { Button } from "../Button";

const AssignSsid = ({data}) => {
  const [loading, setLoading] = useState(true);
  const [ssid, setSsid] = useState("");
  const [password, setPassword] = useState("");
  const [msg, setMsg] = useState({ error: false, msg: "" });
  const [savedMacs, setSavedMacs] = useState([]);
  const [filteredMacs, setFilteredMacs] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isMacValid, setIsMacValid] = useState(true);
  const [mac, setMac] = useState({
    mac1: "",
    mac2: "",
    mac3: "",
    mac4: "",
    mac5: "",
    mac6: "",
  });

  const macs = ["mac1", "mac2", "mac3", "mac4", "mac5", "mac6"];

  const handleMac = (e) => {
    const { name, value } = e.target;
    const valueUpper = value.toUpperCase();

    if (/^[0-9A-F]*$/.test(valueUpper) && valueUpper.length < 3) {
      setMac((prev) => ({
        ...prev,
        [name]: valueUpper,
      }));
    } else {
      return;
    }

    setSearchTerm(valueUpper);

    if (valueUpper.length === 2) {
      const index = macs.indexOf(name);
      moveFocus(macs[index + 1]);
    } else if (valueUpper.length === 0) {
      const index = macs.indexOf(name);
      moveFocus(macs[index - 1]);
    }
  };

  const moveFocus = (name) => {
    const inputElement = document.querySelector(`input[name="${name}"]`);
    if (inputElement) {
      inputElement.focus();
    }
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleSsid = (e) => {
    setSsid(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Backspace" && e.target.value.length === 0) {
      const index = macs.indexOf(e.target.name);
      moveFocus(macs[index - 1]);
    }
  };

  const handleSubmit = async () => {
    const arrayOfMac = Object.values(mac);

    arrayOfMac.forEach((m) => {
      if(m.length !== 2){ 
        setIsMacValid(false);
        setMsg({error: true, msg:"Invalid MAC"})
        return
      }
    });

    if(!isMacValid) return;

    if (ssid === "" || password === "") {
      return;
    }

    const macAddress = arrayOfMac.join(":");
    const data = {
      mac: macAddress,
      ssid,
      password,
    };

    try {
      setLoading(true);
      const res = await api.post("/ssid/post-mac-ssid", data);

      if (res.status === 201) {
        setSsid("");
        setPassword("");
        setMac({
          mac1: "",
          mac2: "",
          mac3: "",
          mac4: "",
          mac5: "",
          mac6: "",
        });
        setSearchTerm("");
        setMsg({ error: false, msg: "Successfully Saved" });
      } else {
        setMsg({ error: true, msg: "Internal Server Error" });
      }
    } catch (error) {
      setMsg({ error: true, msg: "Something went wrong" });
    }

    setLoading(false);
    setTimeout(() => {
      setMsg({ msg: "" });
    }, 5000);
  };

  useEffect(() => {
    const fetchMacs = async () => {
      try {
        const res = await api.get("/ssid/get-macs");
        setSavedMacs(res.data.macs || []);
        setFilteredMacs(res.data.macs || []);
      } catch (error) {
        console.error("Failed to fetch MACs", error);
      } finally {
        setLoading(false);
      }
    };
    fetchMacs();
  }, []);

  // Filter MAC addresses based on search term
  useEffect(() => {
    const filtered = savedMacs.filter((mac) =>
      mac.toUpperCase().includes(searchTerm.toUpperCase())
    );
    setFilteredMacs(filtered);
  }, [searchTerm, savedMacs]);

  const addMacToInputs = (e) => {
    const mac = e.target.value;

    const arrayOfMac = mac.split(":");
    arrayOfMac.forEach((mac, index) => {
      const name = `mac${index + 1}`;
      setMac((prev) => ({
        ...prev,
        [name]: mac,
      }));
    });
  };

  if (loading) {
    return (
      <div className="flex h-full flex-col justify-center items-center">
        Loading...
      </div>
    );
  }

  return (
    <div className="h-full bg-gray-100">
      <div className="flex mt-3 mb-3 h-full flex-col justify-center items-center">
        <div className={msg.error ? "text-red-500" : "text-green-500"}>
          {msg.msg}
        </div>
        <div>MAC</div>
        <div className="flex space-x-3">
          <div className="w-14 flex items-center">
            <Input
              type="text"
              name="mac1"
              value={mac.mac1}
              onChange={handleMac}
              autoComplete="off"
            />
          </div>
          <Colon />
          <div className="w-14 flex items-center">
            <Input
              type="text"
              name="mac2"
              value={mac.mac2}
              onChange={handleMac}
              onKeyDown={handleKeyDown}
              autoComplete="off"
            />
          </div>
          <Colon />
          <div className="w-14 flex items-center">
            <Input
              type="text"
              name="mac3"
              value={mac.mac3}
              onChange={handleMac}
              onKeyDown={handleKeyDown}
              autoComplete="off"
            />
          </div>
          <Colon />
          <div className="w-14 flex items-center">
            <Input
              type="text"
              name="mac4"
              value={mac.mac4}
              onChange={handleMac}
              onKeyDown={handleKeyDown}
              autoComplete="off"
            />
          </div>
          <Colon />
          <div className="w-14 flex items-center">
            <Input
              type="text"
              name="mac5"
              value={mac.mac5}
              onKeyDown={handleKeyDown}
              onChange={handleMac}
              autoComplete="off"
            />
          </div>
          <Colon />
          <div className="w-14 flex items-center">
            <Input
              type="text"
              name="mac6"
              value={mac.mac6}
              onKeyDown={handleKeyDown}
              onChange={handleMac}
              autoComplete="off"
            />
          </div>
        </div>

        <Input label="SSID" value={ssid} onChange={handleSsid} />
        <Input label="PASSWORD" value={password} onChange={handlePassword} />

        <Button name="Submit" onClick={handleSubmit} />
        {loading ? "Sending" : null}

        <div className="mt-4">
          <h3 className="text-lg font-semibold">Available MAC Addresses:</h3>
          <ul className="flex flex-col space-y-2">
            {filteredMacs.map((mac, index) => (
              <button
                value={mac}
                onClick={addMacToInputs}
                key={index}
                className="text-gray-700 bg-slate-100 px-4 border border-gray-300 rounded-lg"
              >
                {mac}
              </button>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AssignSsid;
