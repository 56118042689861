import { useState } from "react";
import { Button } from "../Button";
import { Input } from "../Form/Input";
import { useNavigate } from 'react-router-dom';
import { guestApi } from "../../api/api";

function Register() {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });

    const [error, setError] = useState('');
    const [success, setSuccess] = useState(''); // State for success messages

    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const submitForm = async (e) => {
        e.preventDefault();

        try {
            await guestApi.post("/auth/register", formData);
            setSuccess("User Registered Successfully");
            setError(''); // Clear any previous error messages

            // Redirect to login after a short delay to show the success message
            setTimeout(() => {
                navigate('/login');
            }, 2000); // 2 seconds delay
        } catch (error) {
            if (error.response && error.response.data) {
                const msg = error.response.data;
                setError(msg.error);
            } else {
                setError("Registration failed. Please try again."); // Fallback error message
            }
            setSuccess(''); // Clear any previous success messages
        }
    };

    return (
      <>
        <div className="flex flex-col justify-center items-center h-screen">

          <div className="flex justify-center mb-4 text-2xl font-bold text-violet-700">
            Register
          </div>

          <div className="flex flex-col">
            <div className="flex justify-center text-red-600 mb-4">{error}</div>
            <div className="flex justify-center text-green-600 mb-4">
              {success}
            </div>
            <form onSubmit={submitForm}>
              <Input
                type="text"
                onChange={handleChange}
                label="Name"
                name="name"
                id="name"
              />
              <Input
                type="text"
                onChange={handleChange}
                label="Username"
                name="email"
                id="email"
              />
              <Input
                type="password"
                onChange={handleChange}
                label="Password"
                name="password"
                id="password"
              />
              <Button name="Submit" />
            </form>
          </div>
        </div>
      </>
    );
}

export default Register;