import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import {Input} from "../Form/Input"
import {Button} from "../Button"

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const { login, isAuthenticated } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
      if (isAuthenticated) {
        navigate("/dashboard");
      }
    }, [isAuthenticated, navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        try {
            const success = await login(email, password);
            if (success) {
                navigate('/dashboard'); // Redirect to dashboard on successful login
            } else {
                setError('Login failed. Please check your credentials.');
            }
        } catch (err) {
            setError('An error occurred. Please try again.');
        }
    };

    return (
        <div className='h-screen flex justify-center items-center'>
            
            <div className="flex flex-col"> 
            <div className="flex justify-center mb-4 text-2xl font-bold text-violet-700">
                Login
            </div>
            <div className="flex justify-center text-red-600 mb-4">
                {error && <p style={{ color: 'red' }}>{error}</p>}
            </div>
            
            <form onSubmit={handleSubmit}>
                <div>
                    <Input type="text"
                        id="email"
                        label="Username"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required />
                </div>
                <div>
                    <Input
                        type="password"
                        id="password"
                        label="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                <Button name="Submit" />
            </form>
            </div>
        </div>
    );
};

export default Login;