import { useState } from "react";
import { Logout } from "../Auth/Logout";
import { Button } from "../Button";
import AssignSsid from "./AssignSsid";
import { EditAssignedMac } from "./EditAssignedMac";

const Dashboard = () => {

  const [assign, setAssign] = useState(false)

  const handleAssignButton = () => {
    setAssign(assign => !assign);
  }

  return (
    <div className="flex flex-col space-y-1">
      <div className="m-2 flex justify-between space-x-4">
        <Button name="Assign MAC to SSID" onClick={handleAssignButton} />
        <Logout />
      </div>

      {assign ? <AssignSsid /> : <EditAssignedMac />}
    </div>
  );
};

export default Dashboard;
