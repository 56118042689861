import { useEffect, useState } from "react";
import TableComponent from "./Table";
import { api } from "../../api/api";

export const EditAssignedMac = () => {
    const [data, setData] = useState([])

    useEffect(() => {
      const fetchData = async () => {
        const res = await api.get("/ssid/get-assigned-macs");
        setData(res.data.macs)
      };

      fetchData();
    }, []);

    return (
      <div className="bg-gray-100">
        <TableComponent data={data} setData={setData} />
      </div>
    );
}