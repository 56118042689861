import { Link } from "react-router-dom";
import { Button } from "./Button";

function Home(){
    return(
        <>
            <div className="flex justify-center items-center h-screen">
                <div className="flex space-x-5">
                    <Link to="/login" >
                        <Button name="Login" />
                    </Link>
                    <Link to="/register" >
                        <Button name="Register" />
                    </Link>
                </div>
            </div>
        </>
    )
}

export default Home;